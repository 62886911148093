<template>
    <div class="page">
        <CompTable ref="comp_table" :title="(orgName || '') + '成员管理'" :columns="columns" :table-api="`/gateway/org/pc/grassrootsAdmin/selectOrgAdminPage?orgCodeSelf=${orgCodeSelf}&communityCode=${communityCode}`">
            <template v-slot:search="data">
                <FormItem label="搜索" :label-width="50" style="margin-bottom: 0">
                    <Input v-model.trim="data.search.keyword" placeholder="输入姓名或手机号" style="width: 160px"></Input>
                </FormItem>
                <FormItem label="在职状态" :label-width="80" style="margin-bottom: 0">
                    <Select v-model="data.search.workStatus" style="width:100px" transfer>
                        <Option value="1">在职</Option>
                        <Option value="2">离职</Option>
                    </Select>
                </FormItem>
                <FormItem label="岗位" :label-width="50" style="margin-bottom: 0">
                    <Select v-model="data.search.postId" style="width:200px" transfer>
                        <Option v-for="(item, idx) in postList" :key="idx" :value="item.postId">{{ item.postName }}</Option>
                    </Select>
                </FormItem>
            </template>
            <template #operate>
                <Button icon="ios-add" type="info" @click="onDisplayImportModal">导入</Button>
                <Button icon="ios-add" type="primary" @click="onDisplayAddForm()">新增</Button>
            </template>
        </CompTable>

        <CompForm
            title="成员"
            ref="add_form"
            height="auto"
            :dataBefore="onAddDataBefore"
            :submitBefore="onAddSubmitBefore"
            submitApi="/gateway/org/pc/grassrootsAdmin/addOrgAdmin"
            edit-api="/gateway/org/pc/grassrootsAdmin/updateOrgAdmin"
            detail-api="/gateway/org/pc/grassrootsAdmin/selectOrgAdminById"
            id-key="adminId"
            :value="formdata"
            @on-submit="$refs.comp_table.onRefresh()"
            @on-close="onCloseForm"
        >
            <template #form-box="data">
                <ElemFormItem title="姓名" name="userName">
                    <Input style="width:350px" v-model.trim="data.form.userName" :maxlength="50" placeholder="请填写姓名"></Input>
                </ElemFormItem>
                <ElemFormItem title="手机号码" name="mobiles">
                    <div class="mobile-box">
                        <div class="item-box" v-for="(item, idx) in getMobiles(data)" :key="item.id">
                            <Input style="width:280px" v-model="item.value" :maxlength="50" placeholder="请填写手机号码"></Input>
                            <Button class="btn" v-if="idx === 0" type="info" @click="onAddMobileItem">添加</Button>
                            <Button class="btn" v-else type="error" @click="onRemoveMobileItem(idx)">移除</Button>
                        </div>
                    </div>
                </ElemFormItem>
                <ElemFormItem title="状态" name="workStatus">
                    <Select v-model="data.form.workStatus" style="width:350px">
                        <Option value="1" def>在职</Option>
                        <Option value="2">离职</Option>
                    </Select>
                </ElemFormItem>
                <ElemFormItem title="负责岗位" :display="!data.form.adminId">
                    <CheckboxGroup v-model="data.form.postId" style="width:400px">
                        <Checkbox v-for="(item, idx) in postList" :key="idx" :label="item.postId" border style="margin: 3px">{{ item.postName }}</Checkbox>
                    </CheckboxGroup>
                </ElemFormItem>
                <ElemFormItem title="负责体系架构" :display="!!data.form.adminRootOrgList && data.form.adminRootOrgList.length > 0">
                    <div class="structure-box">
                        <div class="item-box" v-for="(item, idx) in data.form.adminRootOrgList" :key="idx">
                            <div class="value">{{ item.rootOrgName }}({{ item.postName }})</div>
                            <div class="btn" @click="onRemovePost(data.form, item, idx)">移除</div>
                        </div>
                    </div>
                </ElemFormItem>
            </template>
        </CompForm>

        <CompModal ref="import_modal" title="导入成员" height="auto">
            <ElemTable title="示例" :columns="exampleColumns" :dataList="exampleData" style="padding:20px"></ElemTable>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn" @click="evt.close">取消</div>
                    <div class="operate-btn highlight" @click="onImport">开始导入</div>
                </div>
            </template>
        </CompModal>

        <CompModal ref="qrcode_modal" title="二维码" height="auto">
            <div class="qrcode-box" :id="qrcode_id"></div>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import CompTable from "@/views/jointly/components/comp-table.vue"
import CompForm from "@/views/jointly/components/CompForm.vue"
import ElemFormItem from "@/views/jointly/components/elem-form-item.vue"
import ElemTable from "./components/ElemTable.vue"

import QRCode from "qrcodejs2"
import UserInfo from "./entity/UserInfo"
import proxy from "@/api/proxy.js"
import Request from "../jointly/utils/request"

export default {
    components: { CompModal, CompTable, CompForm, ElemFormItem, ElemTable },

    data() {
        return {
            orgName: null,
            orgCodeSelf: null,
            communityCode: UserInfo.getCommunityCode(),
            // 岗位列表
            postList: null,
            // 成员列表
            memberList: null,
            // 导入示例表头
            exampleColumns: [
                {
                    title: "姓名",
                    key: "userName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "手机号",
                    key: "mobile",
                    minWidth: 250,
                    align: "center",
                },
            ],
            // 导入示例数据
            exampleData: [
                {
                    userName: "张XX",
                    mobile: "135XXXXXXXX",
                },
                {
                    userName: "刘XX",
                    mobile: "189XXXXXXXX",
                },
            ],

            columns: [
                {
                    title: "姓名",
                    key: "userName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "手机号码",
                    key: "mobile",
                    minWidth: 250,
                    align: "center",
                },
                {
                    title: "激活",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: params.row.activeStatus === "1" ? "" : "red",
                                },
                            },
                            params.row.activeStatus ? (params.row.activeStatus === "1" ? "已激活" : "未激活") : "-"
                        )
                    },
                },
                {
                    title: "状态",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.workStatus ? (params.row.workStatus === "1" ? "在职" : "离职") : "-")
                    },
                },
                {
                    title: "负责体系架构",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        if (!params.row.adminRootOrgList || params.row.adminRootOrgList.length === 0) return h("p", "-")
                        let item = params.row.adminRootOrgList[0]
                        return h("p", item.rootOrgName ? `${item.rootOrgName}(${item.postName})` : item.postName)
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 230,
                    render: (h, params) => {
                        return [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDisplayQrcode(params.row.adminId)
                                        },
                                    },
                                },
                                "二维码"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDisplayAddForm(params.row.adminId)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: params.row.workStatus === "1" ? "error" : "primary",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onChangeWorkStatus(params.row)
                                        },
                                    },
                                },
                                params.row.workStatus === "1" ? "离职" : "在职"
                            ),
                        ]
                    },
                },
            ],

            formdata: {
                mobiles: [
                    {
                        id: this.$core.randomString(),
                        value: "",
                    },
                ],
            },

            // 二维码容器ID
            qrcode_id: this.$core.randomString(),
        }
    },

    watch: {
        "formdata.mobiles"(v) {
            if (!v || v?.length == 0) {
                this.formdata.mobiles = [
                    {
                        id: this.$core.randomString(),
                        value: "",
                    },
                ]
            }
        },
    },

    async created() {
        this.orgName = UserInfo.get("orgName")
        this.orgCodeSelf = UserInfo.get("orgCode")
        // 获取岗位列表
        this.getPostList()
    },

    methods: {
        onDisplayAddForm(id) {
            this.$refs.add_form.open(id)
        },

        getPostList() {
            return this.$get("/gateway/org/pc/grassrootsPost/selectPostList").then(res => {
                if (res.code === "200") {
                    this.postList = res.dataList
                }
            })
        },

        getMemberList() {
            return this.$get("/gateway/org/pc/grassrootsAdmin/selectOrgAdminList", {
                orgCodeSelf: this.orgCodeSelf,
                communityCode: UserInfo.getCommunityCode(),
            }).then(res => {
                if (res.code === "200") {
                    this.memberList = res.dataList
                }
            })
        },

        onAddDataBefore(data) {
            const mobiles = []

            for (let i = 0, l = data.mobile?.split(","); i < l?.length; i++) {
                mobiles.push({
                    id: this.$core.randomString(),
                    value: l[i],
                })
            }

            data.mobiles = mobiles

            return data
        },

        /**
         * 添加成员提交事件前置
         */
        onAddSubmitBefore(data) {
            data.communityCode = UserInfo.getCommunityCode()

            const mobiles = []

            for (let i = 0, l = data.mobiles; i < l.length; i++) {
                let v = l[i].value
                v && mobiles.push(l[i].value)
            }

            if (mobiles.length === 0) {
                this.$Message.error("请输入手机号码")
                return false
            }

            data.mobile = mobiles.join(",")
            delete data.mobiles

            if (data.adminId) {
                data.adminOrgPostIdList = data.removeList
            }
        },

        /**
         * 移除岗位
         */
        onRemovePost(form, item, idx) {
            form.adminRootOrgList.splice(idx, 1)

            if (!form.removeList) {
                form.removeList = []
            }

            form.removeList.push(item.adminOrgPostId)
        },

        onDisplayImportModal() {
            this.$refs.import_modal.display()
        },

        onImport() {
            let input = document.createElement("input")

            input.type = "file"
            input.multiple = false
            // Excel 文件
            input.accept = "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            input.onchange = async evt => {
                var file = evt.target.files[0]
                var formdata = new FormData()

                formdata.append("file", file)
                formdata.append("communityCode", UserInfo.getCommunityCode())

                // 上传文件
                this.uploadFile(proxy["/gateway"].target + "/org/pc/grassrootsAdmin/importOrgAdmin", formdata)
                    .then(() => {
                        this.$Message.success("导入成功")
                        // 关闭弹窗
                        this.$refs.import_modal.close()
                        // 刷新列表
                        this.$refs.comp_table.refresh()
                    })
                    .catch(err => {
                        this.$Message.error("导入失败：" + (err || "请检查文件"))
                    })
            }

            document.body.appendChild(input)
            // 点击事件
            input.click()

            // document.removeChild(input)
        },

        /**
         * 上传文件
         */
        uploadFile(url, formdata) {
            return new Promise((resolve, reject) => {
                const request = new XMLHttpRequest()

                request.open("POST", url)

                request.onload = () => {
                    if (request.status === 200) {
                        let res = JSON.parse(request.response || "{code:200}")

                        if (res.code != 200) {
                            return reject(res.desc || "上传失败")
                        }

                        resolve()
                    } else {
                        reject(request.responseText)
                    }
                }
                // 请求头
                request.setRequestHeader("Authorization", sessionStorage.getItem("accessToken"))
                // 异常
                request.onerror = reject
                // 发送
                request.send(formdata)
            })
        },

        onRemoveMobileItem(idx) {
            this.formdata.mobiles.splice(idx, 1)
        },

        onAddMobileItem() {
            this.formdata.mobiles.push({
                id: this.$core.randomString(),
                value: "",
            })
        },

        /**
         * 改变在职状态
         */
        async onChangeWorkStatus(row) {
            var old = await Request.get("/gateway/org/pc/grassrootsAdmin/selectOrgAdminById", {
                adminId: row.adminId,
            })

            old.workStatus = old.workStatus === "1" ? "2" : "1"

            Request.post("/gateway/org/pc/grassrootsAdmin/updateOrgAdmin", old, { json: true }).then(() => {
                this.$Message.success("修改成功")
                // 改变状态
                row.workStatus = old.workStatus
            })
        },

        // 显示二维码页面
        onDisplayQrcode(id) {
            var text = `${proxy["/custom"]}/#/apps/suiyue/pages/communitymobilization/member_info?i=` + id

            if (!this.qrcode) {
                this.qrcode = new QRCode(document.getElementById(this.qrcode_id), {
                    text: encodeURIComponent(text),
                    width: 230, // 高度
                    height: 230, // 宽度
                    colorDark: "#000000", // 前景色
                    colorLight: "#ffffff", // 后景色
                    correctLevel: QRCode.CorrectLevel.H, // 容错级别
                })
            } else {
                this.qrcode.makeCode(text)
            }

            // 显示弹窗
            this.$refs.qrcode_modal.display()
        },

        getMobiles(data) {
            if (!data.form.mobiles?.length) {
                this.formdata.mobiles = [
                    {
                        id: this.$core.randomString(),
                        value: "",
                    },
                ]

                this.$set(data.form, "mobiles", this.formdata.mobiles)
            }

            return data.form.mobiles
        },
    },
}
</script>
<style lang="less" scoped>
.mobile-box {
    .item-box {
        display: flex;
        align-items: center;
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }

        .btn {
            margin-left: 10px;
        }
    }
}
.structure-box {
    .item-box {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }

        .value {
            padding: 6px 8px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            width: 280px;
        }

        .btn {
            cursor: pointer;
            padding: 6px 15px;
            background: #e60000;
            color: #fff;
            border-radius: 4px;
            margin-left: 10px;
        }
    }
}

.qrcode-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
</style>
